<template>
  <div>
    <fieldset v-if="isSelected" class="ml-10">
      <h3 class="font-weight-bold mt-2 mb-2">{{ $t("alerts.wizard.deliveryMode.action.title") }}</h3>
      <span class="wx-typo-sm font-weight-light">
        {{ $t("alerts.wizard.deliveryMode.action.description") }}
      </span>
      <wx-textarea
        v-model="reactiveValue.message"
        :rules="actionMessageRules"
        :label="$t('alerts.wizard.deliveryMode.action.messageInput')"
        :placeholder="$t('alerts.wizard.deliveryMode.action.messageInput')"
        height="80"
        class="mt-2"
        counter
        @blur="emit"
      />
      <wx-text-field
        v-model="reactiveValue.url"
        :rules="actionUrlRules"
        :label="$t('common.url')"
        :placeholder="$t('common.url')"
        class="mt-1"
        @blur="emit"
      />
    </fieldset>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import WxTextarea from "@/components/ui/WxTextarea.vue";
import WxTextField from "@/components/ui/WxTextField.vue";

const actionMessageMaxLength = 500;

export default {
  name: "AttachedAction",
  components: { WxTextarea, WxTextField },
  props: {
    value: {
      type: String,
      default: () => ({ message: null, url: null }),
    },
    alert: {
      type: Object,
      default: null,
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      actionMessageRules: [
        (v) => {
          if (v && v.length > 0) {
            if (v.length <= actionMessageMaxLength) {
              return true;
            } else {
              return this.$t("alerts.wizard.deliveryMode.action.messageTooLong", { maxLength: actionMessageMaxLength });
            }
          } else {
            return true;
          }
        },
      ],
      actionUrlRules: [
        (v) => {
          if (v && v.length > 0) {
            try {
              new URL(v);
              return true;
            } catch (e) {
              return this.$t("alerts.wizard.deliveryMode.action.invalidUrl");
            }
          } else {
            return true;
          }
        },
      ],
    };
  },
  computed: {
    ...mapGetters("navigation", ["factoryUsers"]),
    reactiveValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    initializeData() {
      this.reactiveValue = {
        message: this.alert?.action_message ?? null,
        url: this.alert?.action_url ?? null,
      };
    },
  },
  mounted() {
    this.initializeData();
  },
};
</script>
